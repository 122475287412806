import "./Blog.scss";
import { IBlog } from "../../assets/blogs/blogsList";

interface IProps {
    blog: IBlog
}

function Blog(props: IProps) {
    const { blog } = props;

    return (<div className="Blog">

        <div className="header-image" style={{ backgroundImage: "url(" + blog.image + ")" }} />

        <div className="section__content default-color">
            <div className="blog__category">{blog.category}</div>
            <div className="blog__title title-xxl">{blog.title}</div>
            <div className="blog__body">
                {blog.text}
            </div>
        </div>
    </div>);
}

export default Blog;