import "./Terms.scss";
import { Link } from "react-router-dom";

function Terms() {
    return (<div className="Terms">
        <div className="section__content default-color">
            <h1 className="title-xxl page-title">
                Terms & conditions
            </h1>

            <p>1. Acceptance of Terms:<br></br>By using the PAYD platform ("Platform"), you agree to comply with and be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, you must not use our services.</p>

            <p>2. User Accounts:</p>

            <p>2.1. To use certain features of the Platform, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

            <p>2.2. You must provide accurate and complete information when creating your account, and you agree to update your information to keep it accurate and current.</p>

            <p>3. Payment Processing:</p>

            <p>3.1. PAYD facilitates the processing of payments between payers and payees. We do not have control over, and are not responsible for, the quality, safety, legality, or delivery of goods or services purchased through the Platform.</p>

            <p>3.2. Transactions conducted through PAYD are subject to fees, which are outlined in our Fee Schedule.</p>

            <p>4. User Conduct:</p>

            <p>4.1. You agree not to use the Platform for any unlawful or prohibited purpose or to engage in any activity that violates these Terms and Conditions.</p>

            <p>4.2. You shall not attempt to gain unauthorized access to any part of the Platform, including other users' accounts.</p>

            <p>5. Privacy:</p>

            <p>Your use of the Platform is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information.</p>

            <p>6. Disclaimers and Limitation of Liability:</p>

            <p>6.1. PAYD provides the Platform "as is" and makes no warranties regarding the accuracy, reliability, or availability of our services. We disclaim all warranties, whether express or implied.</p>
            <p>6.2. In no event shall PAYD be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Platform or the inability to use it.</p>
            <p>7. Termination:</p>
            <p>PAYD reserves the right to suspend or terminate your account at our sole discretion for any reason, including violation of these Terms and Conditions.</p>
            <p>8. Changes to Terms:</p>
            <p>PAYD may update these Terms and Conditions at any time. You are responsible for reviewing them periodically, and your continued use of the Platform after changes have been made constitutes acceptance of those changes.</p>
            <p>9. Governing Law:</p>
            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the United Arab Emirates without regard to its conflict of law principles.</p>
            <p>10. Contact Us:</p>
            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <Link to="mailto:info@PAYD.com">info@PAYD.com</Link></p>
        </div>
    </div>);
}

export default Terms;