import ReactDOM from 'react-dom/server';
import { Link, useSearchParams } from "react-router-dom";
import "./Search.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Home from '../Home/Home';
import Services from '../Services/Services';
import Contact from '../Contact/Contact';
import { useEffect, useState } from 'react';

const pagesToSearch = [
    {
        page: <Home />,
        title: "Main page",
        href: "/"
    },
    {
        page: <Services />,
        title: "Services page",
        href: "/services"
    },
    {
        page: <Contact />,
        title: "Contact us page",
        href: "/contact"
    },
];

interface ISearchResults {
    title: string,
    href: string
};

function Search() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchResults, setSearchResults] = useState<ISearchResults[]>([]);
    const [searchValue, setSearchValue] = useState("");

    function handleSearch(event: any) {
        let search;
        if (event.target.value) {
            search = {
                search: event.target.value
            }
        } else {
            search = undefined;
        }

        setSearchParams(search, { replace: true });
    }

    function search(q: string) {
        let results: ISearchResults[] = [];
        for (let i = 0; i < pagesToSearch.length; i++) {
            const dom = document.createElement('div');
            dom.innerHTML = ReactDOM.renderToStaticMarkup(pagesToSearch[i].page);

            if (dom.innerText.toLowerCase().includes(q.toLowerCase())) {
                results.push({
                    title: pagesToSearch[i].title,
                    href: pagesToSearch[i].href
                });
            }
        }
        return results;
    }

    useEffect(() => {
        const param = searchParams.get("search");
        if (param) {
            setSearchResults(search(param.trim()));
            setSearchValue(param);
        } else setSearchValue("");

        // eslint-disable-next-line
    }, [searchParams, searchParams.get("search")]);

    return (<div className="Search">
        <div className="section__content default-color">
            <div className="search-bar">
                <div className="content">
                    <div className="search-container">
                        <input type="text" onChange={handleSearch} value={searchValue} />
                        <div className="search-icon">
                            <FontAwesomeIcon icon={faSearch} color="#AAB7B8" size="lg" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="search-results">
                {searchResults.length === 0 && <div className="search-results__not-found">Sorry. We didn't find any results for {searchParams.get("search")}</div>}

                {searchResults.length !== 0 && <>
                    <div className="search-results__total">{searchResults.length} results for {searchParams.get("search")}</div>
                    <hr />

                    {searchResults.map((item) => <div className="search-results__item">
                        <Link to={item.href}>
                            <h1>{item.title}</h1>
                            <h6>{window.location.origin + item.href}</h6>
                        </Link>
                    </div>)}
                </>}
            </div>
        </div>
    </div>);
}

export default Search;