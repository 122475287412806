import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Contact.scss";
import { faAt, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";

function Contact() {
    return (<div className="Contact">
        <div className="section__content default-color">
            <div className="header-content">
                <h1 className="title-xxl">
                    Contact our team
                </h1>
                <p>Submit your information and our we will get in touch with you shortly.</p>
            </div>

            <div className="contact-form">
                <div className="fields">
                    <input placeholder="Name" type="text" autoComplete="name" />
                    <input placeholder="Email" type="email" autoComplete="email" />
                    <input placeholder="Phone" type="tel" autoComplete="tel" />
                    <textarea placeholder="Message" />
                </div>
                <div className="button">
                    <Button size="m">Submit</Button>
                </div>
            </div>

            <div className="details">
                <div className="details__item">
                    <div className="icon"><FontAwesomeIcon icon={faLocationDot} color="4083db" /></div>
                    <p>Al Masa 4 – King Faisal Rd. – Umm Al Quwain – UAE</p>
                </div>
                <a className="details__item" href="tel:+97167433366">
                    <div className="icon"><FontAwesomeIcon icon={faPhone} color="4083db" /></div>
                    <p>+971 6 743 3366</p>
                </a>
                <a className="details__item" href="mailto:info@payd.ae">
                    <div className="icon"><FontAwesomeIcon icon={faAt} color="4083db" /></div>
                    <p>info@payd.ae</p>
                </a>
            </div>
        </div>
    </div>);
}

export default Contact;