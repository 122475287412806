import "./Cookie.scss";
import { Link } from "react-router-dom";

function Cookie() {
    return (<div className="Cookie">
        <div className="section__content default-color">
            <h1 className="title-xxl page-title">
            Cookie Policy 
            </h1>

            <p>1. Introduction</p>

            <p>Welcome to OnnPay. This Cookie Policy explains how we use cookies and similar technologies when you visit our website. By using our website, you consent to the use of cookies as described in this policy.</p>

            <p>2. What Are Cookies?</p>

            <p>Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit a website. They are widely used to make websites work or function more efficiently, as well as to provide information to website owners.</p>

            <p>3. Types of Cookies We Use</p>

            <p>3.1. Essential Cookies: These cookies are necessary for the basic functioning of our website and enable core features, such as secure login and payment processing. You cannot opt-out of these cookies.</p>

            <p>3.2. Analytics Cookies: We use these cookies to collect information about how visitors use our website, including the number of visitors, the pages they visit, and how they interact with the site. The information collected is anonymous and is used for statistical purposes to improve our website's performance and user experience.</p>

            <p>3.3. Functional Cookies: These cookies allow our website to remember choices you make (e.g., language or region preferences) and provide enhanced, more personalized features.</p>

            <p>3.4. Advertising Cookies: We do not use advertising cookies on our website.</p>

            <p>4. How to Manage Cookies</p>

            <p>4.1. Most web browsers allow you to manage cookies through your browser settings. You can usually find these settings in the "Options" or "Preferences" menu of your browser.</p>

            <p>4.2. You can choose to block or delete cookies, but doing so may affect your ability to use certain features of our website.</p>

            <p>5. Third-Party Cookies</p>

            <p>5.1. Our website may contain links to third-party websites or services. These third-party sites may use cookies that are beyond our control. We recommend reviewing the privacy and cookie policies of any third-party websites you visit.</p>
            <p>6. Changes to this Cookie Policy</p>
            <p>6.1. We may update this Cookie Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting a revised Cookie Policy on our website.</p>
            <p>7. Contact Us</p>
            <p>7.1. If you have questions, concerns, or requests related to this Cookie Policy, please contact us at <Link to="mailto:info@onnpay.com">info@onnpay.com</Link></p>
            
        </div>
    </div>);
}

export default Cookie;