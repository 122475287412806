import React from 'react'

import FirstSectionImage from "../../assets/images/first-section.webp";
import FourthSectionImage from "../../assets/images/fourth-section-graphic.webp";
import FifthSectionImage from "../../assets/images/fifth-section.svg";
import SixthSectionImage from "../../assets/images/sixth-section.webp";
import SeventhSectionImage from "../../assets/images/seventh-section.svg";
import EighthSectionImage from "../../assets/images/eighth-section.webp";
import TenthSectionImage from "../../assets/images/tenth-section.webp";

import Partner1Image from "../../assets/images/partners/payd.png";
import Partner2Image from "../../assets/images/partners/APS.svg";


import Button from "../../components/Button/Button";
import "./Home.scss";
import InfoCard from '../../components/InfoCard/InfoCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCompass, faCompassDrafting, faEarthEurope, faGlobe, faGraduationCap, faHouseCrack, faLink, faMobile, faQrcode, faReceipt, faRocket, faShop, faTableColumns, faUserDoctor } from '@fortawesome/free-solid-svg-icons';

interface ICard {
    icon?: JSX.Element,
    header?: string,
    text: string
}

const cards: ICard[] = [
    {
        icon: <FontAwesomeIcon icon={faLink} color="#4083db" size="lg" />,
        header: "Payment Links",
        text: "By creating a payment link that you can send to your clients by email, WhatsApp, or SMS, you may accept payments from anywhere online."
    },
    {
        icon: <FontAwesomeIcon icon={faQrcode} color="#40ccdb" size="lg" />,
        header: "Pay by QR Code",
        text: "Simplify payment processes with a scannable QR code that takes your customer to your branded payment page just using a smartphone camera."
    },
    {
        icon: <FontAwesomeIcon icon={faReceipt} color="#fc5656" size="lg" />,
        header: "Auto Invoicing",
        text: "Merchants can go paperless just by uploading all documents and proof of address along with the company logo."
    },
    {
        icon: <FontAwesomeIcon icon={faEarthEurope} color="#1fad26" size="lg" />,
        header: "Local Payments",
        text: "Enable prevalent regional payment options from the MENA region to give your consumers a comfortable buying experience."
    },
    {
        icon: <FontAwesomeIcon icon={faTableColumns} color="#be2cdb" size="lg" />,
        header: "Interactive Dashboard",
        text: "Our Dashboard has all the merchant details including their transactions and services they can use."
    },
    {
        icon: <FontAwesomeIcon icon={faCode} color="#e6cf10" size="lg" />,
        header: "Ecommerce APIs",
        text: "The merchant will get access to the developer tool, allowing them to connect the services to their websites and mobile applications via our API documentation."
    },
];

const paymentCards = [
    {
        icon: <FontAwesomeIcon icon={faGlobe} color="#242e3d" size="3x" />,
        text: "Payments for Ecommerce"
    },
    {
        icon: <FontAwesomeIcon icon={faGraduationCap} color="#242e3d" size="3x" />,
        text: "Payments for Education"
    },
    {
        icon: <FontAwesomeIcon icon={faCompass} color="#242e3d" size="3x" />,
        text: "Payments for Travel"
    },
    {
        icon: <FontAwesomeIcon icon={faRocket} color="#242e3d" size="3x" />,
        text: "Payments for Startups"
    },
    {
        icon: <FontAwesomeIcon icon={faShop} color="#242e3d" size="3x" />,
        text: "Payments for Retail"
    },
    {
        icon: <FontAwesomeIcon icon={faHouseCrack} color="#242e3d" size="3x" />,
        text: "Payments for Insurance"
    },
    {
        icon: <FontAwesomeIcon icon={faUserDoctor} color="#242e3d" size="3x" />,
        text: "Payments for Healthcare"
    },
];

const partners: string[] = [
    // Partner1Image,
    Partner2Image,
];

function Home() {
    return (
        <div className="Home">
            <section id="first-section">
                <div className="section__content">
                    <div className="heading default-color">
                        <h1 className="heading__header">
                            Designed for<br />
                            every industry
                        </h1>
                        <p className="heading__body">
                            Streamline your payment processes, expand your business, and find market information to make data-driven decisions. We have the solution you need.
                        </p>
                        {/* <Button size="m">
                            Get started now!
                        </Button> */}
                        <a target="_blank" href="https://www.payd.ae/register/"> <Button size="m">
                            Get started now!
                        </Button> </a>
                    </div>

                    <div className="graphic">
                        <img src={FirstSectionImage} alt="" />
                    </div>
                </div>
            </section>

            <section id="second-section">
                <div className="section__content">
                    <div className="heading default-color">
                        <h1 className="heading__header title-xxl">
                            Assisting you in improving your business operations
                        </h1>
                        <p className="heading__body">
                            We create and build our features so that you can benefit from our straightforward, secure, and cutting-edge services. For your employees and customers, we aim to make payments simple.
                        </p>
                    </div>
                </div>
            </section>

            <section id="third-section">
                <div className="section__content">
                    <div className="cards">
                        {cards.map((item) => <InfoCard
                            icon={item.icon}
                            header={item.header}
                        >
                            {item.text}
                        </InfoCard>)}
                    </div>
                </div>
            </section>

            <section id="fourth-section">
                <div className="section__content">
                    <div className="graphic side-content">
                        <img src={FourthSectionImage} alt="" />
                    </div>
                    <div className="info side-content default-color">
                        <div className="header">
                            <div className="icon">
                                <FontAwesomeIcon icon={faLink} color="#4083db" size="xl" />
                            </div>
                            <h6>Process payments</h6>
                        </div>

                        <h1>
                            We provide solutions to<br />
                            make payment processes simple<br />
                            from virtually anywhere
                        </h1>
                        <p>
                            No matter the size of your company, our platform will help you with creating a unique payment solution that meets your needs.
                        </p>
                    </div>
                </div>
            </section>

            <section id="fifth-section">
                <div className="section__content">
                    <div className="info side-content default-color">
                        <h1>
                            Available for businesses<br />
                            all over MENA
                        </h1>
                        <p>
                            We have local offices and acquirers in the GCC and other MENA nations, as well as regional experts in payment processing technology, who can provide solutions catered to your local currency.
                        </p>
                    </div>

                    <div className="graphic side-content">
                        <img src={FifthSectionImage} alt="" />
                    </div>
                </div>
            </section>

            <section id="sixth-section">
                <div className="section__content">
                    <div className="info side-content default-color">
                        <div className="header">
                            <div className="icon">
                                <FontAwesomeIcon icon={faEarthEurope} color="#4083db" size="xl" />
                            </div>
                            <h6>Process payments</h6>
                        </div>

                        <h1>
                            Grow your company,<br />
                            attract new clients, and<br />
                            accept more local payments
                        </h1>
                        <p>
                            Provide your clients an instant sense of familiarity with local payment methods anywhere in the MENA Region.
                        </p>
                    </div>
                    <div className="graphic side-content">
                        <img src={SixthSectionImage} alt="" />
                    </div>
                </div>
            </section>

            <section id="seventh-section">
                <div className="section__content">
                    <div className="graphic side-content">
                        <img src={SeventhSectionImage} alt="" />
                    </div>
                    <div className="info side-content default-color">
                        <div className="header">
                            <div className="icon">
                                <FontAwesomeIcon icon={faTableColumns} color="#4083db" size="xl" />
                            </div>
                            <h6>Interactive Dashboard</h6>
                        </div>

                        <h1>
                            User-friendly and straightforward<br />
                            dashboard with merchant<br />
                            details and transactions
                        </h1>
                        <p>
                            Let us handle your cash collections and deposits and you can monitor them on the dashboard.
                        </p>
                    </div>
                </div>
            </section>

            <section id="eighth-section">
                <div className="section__content">
                    <div className="info side-content default-color">
                        <div className="header">
                            <div className="icon">
                                <FontAwesomeIcon icon={faReceipt} color="#4083db" size="xl" />
                            </div>
                            <h6>Auto invoicing</h6>
                        </div>

                        <h1>
                            Automatically generate<br />
                            an invoice with<br />
                            embedded payment link
                        </h1>
                        <p>
                            Digital Receipts for your online payments and no hassle of setting up complex systems. You register with us, upload your company details and E-receipts are ready to be sent out.
                        </p>
                    </div>
                    <div className="graphic side-content">
                        <img src={EighthSectionImage} alt="" />
                    </div>
                </div>
            </section>

            <section id="tenth-section">
                <div className="section__content">
                    <div className="info side-content default-color">
                        <div className="header">
                            <div className="icon">
                                <FontAwesomeIcon icon={faCode} color="#4083db" size="xl" />
                            </div>
                            <h6>Ecommerce APIs</h6>
                        </div>

                        <h1>
                            Make online payment an<br />
                            option for your online business’<br />
                            ease of transaction
                        </h1>
                        <p>
                            With an access to the developer tool, merchants can use PAYD’s API documentation connecting the services to their website and mobile application.
                        </p>
                    </div>
                    <div className="graphic side-content">
                        <img src={TenthSectionImage} alt="" />
                    </div>
                </div>
            </section>

            <section id="eleventh-section">
                <div className="section__content default-color">
                    <div className="title-xxl">
                        Make payments easier with scan to pay QR Codes
                    </div>

                    <div className="cards">
                        <div className="side-content">
                            <div className="header-content">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faMobile} color="#4083db" size="2xl" />
                                </div>
                                <h6 className="title-xxl">Direct from your phone</h6>
                            </div>
                            <p>There’s no need to install a 3rd party application to scan the code.<br />It can be done directly from any smartphone camera.</p>
                        </div>
                        <div className="side-content">
                            <div className="header-content">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCompassDrafting} color="#4083db" size="2xl" />
                                </div>
                                <h6 className="title-xxl">Customized merchant page</h6>
                            </div>
                            <p>The QR code will redirect your client to your<br />branded payment page.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="twelfth-section">
                <div className="section__content">
                    <div className="heading default-color">
                        <h1 className="heading__header title-xxl">
                            Payments in your industry
                        </h1>
                        <p className="heading__body">
                            Learn more about how PAYD can enable payments for your sector, including healthcare, insurance, and more.
                        </p>
                    </div>

                    <div className="cards">
                        {paymentCards.map((item) => <div className="cards__item">
                            <div className="icon">{item.icon}</div>
                            <div className="text">{item.text}</div>
                        </div>)}
                    </div>
                </div>
            </section>

            <section id="thirteenth-section">
                <div className="section__content">
                    <div className="header-content default-color">
                        <h1>
                        Powered by
                        </h1>
                    </div>

                    <div className="cards">
                        {partners.map((item) => <div className="cards__item">
                            <img src={item} alt="" />
                        </div>)}
                    </div>
                </div>
            </section>

            <section id="fourteenth-section">
                <div className="section__content">
                    <div className="header-content default-color">
                        <h1 className="title-xxl">
                            Unlock your full business potential
                        </h1>

                        {/* <Button size="m">Join us</Button> */}
                        <a target="_blank" href="https://www.payd.ae/register/"> <Button size="m">Join us</Button> </a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;