import "./Header.scss";

import { faBars, faCompass, faGlobe, faGraduationCap, faHouseCrack, faRocket, faSearch, faShop, faUserDoctor, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/payd.png";
import Button from "../Button/Button";

interface IMenuItems {
    title: string,
    link?: string,
    items?: {
        title: string,
        icon: JSX.Element,
        link: string
    }[]
}

const menuItems: IMenuItems[] = [
    {
        title: "Services",
        link: "services"
    },
    {
        title: "Industries",
        items: [
            {
                icon: <FontAwesomeIcon icon={faGlobe} color="#242e3d" size="2x" />,
                title: "Ecommerce",
                link: ""
            },
            {
                icon: <FontAwesomeIcon icon={faGraduationCap} color="#242e3d" size="2x" />,
                title: "Education",
                link: ""
            },
            {
                icon: <FontAwesomeIcon icon={faCompass} color="#242e3d" size="2x" />,
                title: "Travel",
                link: ""
            },
            {
                icon: <FontAwesomeIcon icon={faRocket} color="#242e3d" size="2x" />,
                title: "Startups",
                link: ""
            },
            {
                icon: <FontAwesomeIcon icon={faShop} color="#242e3d" size="2x" />,
                title: "Retail",
                link: ""
            },
            {
                icon: <FontAwesomeIcon icon={faHouseCrack} color="#242e3d" size="2x" />,
                title: "Insurance",
                link: ""
            },
            {
                icon: <FontAwesomeIcon icon={faUserDoctor} color="#242e3d" size="2x" />,
                title: "Healthcare",
                link: ""
            },
        ]
    },
    {
        title: "Blog",
        link: "blog"
    },
    {
        title: "Contact us",
        link: "contact"
    },
];

function Header() {
    const [sideMenu, setSideMenu] = useState(false);
    const [searchMenu, setSearchMenu] = useState(false);
    const [activeMenu, setActiveMenu] = useState(-1);
    const navigate = useNavigate();

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const searchValue = e.currentTarget.getElementsByTagName("input")[0].value;

        navigate("/search?search=" + searchValue);
        setSearchMenu(false);
        setSideMenu(false);
    }

    return (<>
        <header className="Header">
            <div className="section__content">
                <div className="side-content">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="logo" width={120} />
                        </Link>
                    </div>
                    {menuItems.map((item) => <div className="menu-item onlyweb">
                        {item.link ? <Link to={item.link}>
                            {item.title}
                        </Link> : <>{item.title}</>}

                        {item.items && <div className="dropdown">
                            {item.items.map((item2) => <Link to={item2.link}>
                                <div className="dropdown__item">
                                    {item2.icon}
                                    {item2.title}
                                </div>
                            </Link>)}
                        </div>}
                    </div>)}
                </div>

                <div className="side-content">
                    <div className="join-us onlyweb">
                        {/* <Button size="s">Join us</Button> */}
                        <a target="_blank" href="https://www.payd.ae/register/"> <Button size="m">Join us</Button> </a>
                    </div>
                    <div className="join-us onlyweb">
                        <a target="_blank" href="https://www.payd.ae/admin/login"> <Button size="m">Login</Button> </a>
                    </div>

                    <div className="search-icon onlyweb" onClick={() => setSearchMenu(true)}>
                        <FontAwesomeIcon icon={faSearch} color="#AAB7B8" size="lg" />
                    </div>
                    
                    <div className="mobile-menu-icon onlymobile" onClick={() => setSideMenu(true)}>
                        <FontAwesomeIcon icon={faBars} color='#222222' />
                    </div>
                </div>
            </div>

            <div className={"onlyweb search-bar" + (searchMenu ? " active" : "")} onClick={(e) => {
                var element = e.target as HTMLElement;
                if (element.tagName !== "INPUT") {
                    setSearchMenu(false);
                }
            }}>
                <div className="section__content">
                    <div></div>

                    <form onSubmit={handleSubmit}>
                        <div className="search-container">
                            <input name="search" type="text" />
                            <div className="search-icon">
                                <FontAwesomeIcon icon={faSearch} color="#AAB7B8" size="lg" />
                            </div>
                        </div>
                    </form>

                    <div className="icon-close">
                        <FontAwesomeIcon icon={faXmark} color="#FFFFFF" size="xl" />
                    </div>
                </div>
            </div>

            <div className={"onlymobile full-screen-menu" + (sideMenu ? " active" : "")} style={{ top: window.screenY }}>
                <div className="top">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="logo" width={150} />
                        </Link>
                    </div>

                    <FontAwesomeIcon icon={faXmark} onClick={() => setSideMenu(false)} size="xl" />
                </div>
                <div className="menu-item">
                    <form onSubmit={handleSubmit}>
                        <div className="search-container">
                            <input type="text" />
                            <div className="search-icon">
                                <FontAwesomeIcon icon={faSearch} color="#AAB7B8" size="lg" />
                            </div>
                        </div>
                    </form>
                </div>
                {menuItems.map((item, index) => <div className="menu-item" onClick={() => {
                    if (!item.link) {
                        if (activeMenu !== index) setActiveMenu(index);
                        else setActiveMenu(-1);
                    }
                }}>
                    {item.link ? <Link to={item.link} onClick={() => setSideMenu(false)}>
                        {item.title}
                    </Link> : <>{item.title}</>}

                    {activeMenu === index && item.items && <div className="dropdown">
                        {item.items.map((item2) => <Link to={item2.link} onClick={() => setSideMenu(false)}>
                            <div className="dropdown__item">
                                {item2.icon}
                                {item2.title}
                            </div>
                        </Link>)}
                    </div>}
                </div>)}
                <div className="menu-item">
                    Join us
                </div>
            </div>
        </header>

        <div style={{ height: 82 }} />
    </>);
}

export default Header;