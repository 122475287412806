import React from "react";
import "./InfoCard.scss";

interface IProps {
    icon?: JSX.Element,
    header?: string,
    children: string,
    // linkTitle?: string,
    // linkParams?: React.HTMLProps<HTMLAnchorElement>
}

function InfoCard(props: IProps) {
    const { icon, header, children/*, linkTitle, linkParams */ } = props;

    return (
        <div className="InfoCard default-color">
            <div className="InfoCard__header">
                {icon && <div className="icon">
                    {icon}
                </div>}
                <h6>{header}</h6>
            </div>
            <div className="InfoCard__body">
                {children}
            </div>
            {/* <div className="InfoCard__button">
                <a
                    {...linkParams}
                >
                    {linkTitle}
                </a>
            </div> */}
        </div>
    );
}

export default InfoCard;