import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlogCard from "../../components/BlogCard/BlogCard";
import "./Blogs.scss";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { IBlog, blogsList } from "../../assets/blogs/blogsList";
import { slugify } from "../..";

let randomBlog: IBlog = blogsList[Math.floor(Math.random() * blogsList.length)];

function Blogs() {

    const [dropdownCategoryMenu, setDropdownCategoryMenu] = useState(false);
    const [categoriesList, setCategoriesList] = useState<string[]>([]);
    const [activeCategory, setActiveCategory] = useState("All categories");
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        let tempCategoriesList: string[] = [];
        for (const blog of blogsList) {
            if (tempCategoriesList.indexOf(blog.category) === -1) tempCategoriesList.push(blog.category);
        }
        setCategoriesList(tempCategoriesList);
    }, []);

    function textContent(elem: React.ReactElement | string): string {
        if (!elem) {
            return '';
        }
        if (typeof elem === 'string') {
            return elem;
        }

        const children = elem.props && elem.props.children;
        if (children instanceof Array) {
            return children.map(textContent).join('');
        }
        return textContent(children);
    }

    return (<div className="Blogs">
        <div className="section__content">
            <div className="header-content">
                <h1 className="title-xxl">Blog</h1>
                <h6>Inside PAYD</h6>
            </div>
        </div>

        <div className="search-container">
            <div className="section__content">
                <div className="filter" onClick={() => setDropdownCategoryMenu(((current) => !current))}>
                    <h1>{activeCategory}</h1>
                    <FontAwesomeIcon icon={faChevronDown} color="#AAB7B8" style={{ transform: `rotate(${dropdownCategoryMenu ? 180 : 0}deg)` }} />

                    <div className={"dropdown" + (dropdownCategoryMenu ? " active" : "")}>
                        <div className="dropdown__item" onClick={() => setActiveCategory("All categories")}>
                            All categories
                        </div>
                        {categoriesList.map((item) => <div className="dropdown__item" onClick={() => setActiveCategory(item)}>
                            {item}
                        </div>)}
                    </div>
                </div>
                <div className="search-bar">
                    <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                    <FontAwesomeIcon className="button" icon={faSearch} color="#000000" />
                </div>
            </div>
        </div>

        <div className="section__content blogs">
            {activeCategory === "All categories" && searchValue.trim().length === 0 && <BlogCard
                size="l"
                image={randomBlog.image}
                category={randomBlog.category}
                text={textContent(randomBlog.text).split(".")[0] + "."}
                href={slugify(randomBlog.title)}
            >
                {randomBlog.title}
            </BlogCard>}

            <div className="blogs-list">

                {blogsList.map((item) => (activeCategory === "All categories" || activeCategory === item.category) && (searchValue.trim().length === 0 || item.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) && <BlogCard
                    size="m"
                    image={item.image}
                    category={item.category}
                    text={textContent(item.text).split(".")[0] + "."}
                    href={slugify(item.title)}
                >
                    {item.title}
                </BlogCard>)}
            </div>
        </div>
    </div>);
}

export default Blogs;