import "./Services.scss";

import FirstSectionImage from "../../assets/images/services-first-section.webp";
import SecondSectionImage from "../../assets/images/services-second-section.webp";
import ThirdSectionImage from "../../assets/images/services-third-section.webp";
import FourthSectionImage from "../../assets/images/services-fourth-section.webp";
import FifthSectionImage from "../../assets/images/services-fifth-section.webp";
import SixthSectionImage from "../../assets/images/services-sixth-section.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faEarthEurope, faLink, faQrcode, faReceipt, faTableColumns } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";

interface IService {
    icon: JSX.Element,
    iconText: string,
    header: string,
    body: string,
    image: string
}

const services: IService[] = [
    {
        icon: <FontAwesomeIcon icon={faLink} color="#4083db" size="xl" />,
        iconText: "Payment Links",
        header: "Facilitate payment processing\nfor your clients with links",
        body: "By providing outstanding payment experiences to your clients through payment links, you may increase their faith in you. Quickly and securely accept all payment types.",
        image: FirstSectionImage
    },
    {
        icon: <FontAwesomeIcon icon={faQrcode} color="#4083db" size="xl" />,
        iconText: "QR Codes",
        header: "The easy way\nto pay",
        body: "Streamline payment procedures by providing your customers with a QR code they can scan with their smartphone camera to access your branded payment page.",
        image: SecondSectionImage
    },
    {
        icon: <FontAwesomeIcon icon={faReceipt} color="#4083db" size="xl" />,
        iconText: "Auto Invoicing",
        header: "Reduce hefty costs of\ntraditional AP practices",
        body: "By simply uploading all paperwork, address proof, and the company logo, merchants can go paperless.",
        image: ThirdSectionImage
    },
    {
        icon: <FontAwesomeIcon icon={faEarthEurope} color="#4083db" size="xl" />,
        iconText: "Local Payments",
        header: "Solutions tailored to\nyour local currency",
        body: "Enable popular MENA area payment choices to give your customers a comfortable shopping experience.",
        image: FourthSectionImage
    },
    {
        icon: <FontAwesomeIcon icon={faTableColumns} color="#4083db" size="xl" />,
        iconText: "Interactive Dashboard",
        header: "Connect with data and\nbusiness transactions",
        body: "All of the merchant information, including their transactions and available services, is available on our dashboard.",
        image: FifthSectionImage
    },
    {
        icon: <FontAwesomeIcon icon={faCode} color="#4083db" size="xl" />,
        iconText: "Ecommerce APIs",
        header: "The final touch to\nyour online shop",
        body: "The merchant will get access to the developer tool, enabling them to use our API documentation to link the services to their websites and mobile applications.",
        image: SixthSectionImage
    }
];

function Services() {
    return (<div className="Services">
        {services.map((item, index) => <section>
            <div className="section__content">
                {index % 2 === 1 && <div className="graphic side-content">
                    <img src={item.image} alt="" />
                </div>}
                <div className="info side-content default-color">
                    <div className="header">
                        <div className="icon">{item.icon}</div>
                        <h6>{item.iconText}</h6>
                    </div>

                    <h1>{item.header}</h1>
                    <p>
                        {item.body}
                    </p>
                </div>

                {index % 2 === 0 && <div className="graphic side-content">
                    <img src={item.image} alt="" />
                </div>}
            </div>
        </section>)}

        <section className="footer">
            <div className="section__content">
                <h1 className="title-xxl">
                    Process payments from more people and expand your customer base
                </h1>
                {/* <Button size="m">Get started now!</Button> */}
                <a target="_blank" href="https://www.payd.ae/register/"> <Button size="m">
                            Get started now!
                        </Button> </a>
            </div>
        </section>
    </div>);
}

export default Services;