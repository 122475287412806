import React, { useEffect } from 'react';
import Feedback from './components/Feedback/Feedback';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Feedback />
    </div>
  );
}

export default App;
