import "./Button.scss";

interface IProps {
    children: string,
    size: "s" | "m"
}

function Button(props: IProps) {
    const { children, size } = props;

    return (
        <div className={"Button default-font size-" + size}>
            {children}
        </div>
    );
}

export default Button;