import "./Footer.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/payd.png";
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
    return (<footer>
        <div className="section__content default-color">
            <div className="links">
                <div className="column">
                    <Link to="/services">
                        <div className="footer__header">Services</div>
                    </Link>
                    <div className="footer__item">Payment Links</div>
                    <div className="footer__item">Local Payments</div>
                    <div className="footer__item">Pay by QR Code</div>
                    <div className="footer__item">Interactive Dashboard</div>
                    <div className="footer__item">Auto Invoicing</div>
                    <div className="footer__item">Ecommerce APIs</div>
                </div>
                <div className="column">
                    <div className="footer__header">Industries</div>
                    <div className="footer__item">Ecommerce</div>
                    <div className="footer__item">Retail</div>
                    <div className="footer__item">Education</div>
                    <div className="footer__item">Insurance</div>
                    <div className="footer__item">Travel</div>
                    <div className="footer__item">Healthcare</div>
                    <div className="footer__item">Startups</div>
                </div>
                <div className="column">
                    <div className="footer__header">Company</div>
                    <Link to="/blog">
                        <div className="footer__item">Blogs</div>
                    </Link>
                    <Link to="/about">
                        <div className="footer__item">About us</div>
                    </Link>
                </div>
                <div className="column">
                    <div className="footer__header">Resources</div>
                    <Link to="/terms">
                        <div className="footer__item">Terms and Conditions</div>
                    </Link>
                    <Link to="/privacy">
                        <div className="footer__item">Privacy Statement</div>
                    </Link>
                </div>
            </div>

            <div className="footer__info">
                <div className="side-content">
                    <div className="copyright">
                        {/* © {new Date().getFullYear()} OnnPay.com or its affiliates */}
                        © 2019 PAYD or its affiliates
                    </div>
                    <div className="logo">
                        <img src={logo} alt="logo" width={120} />
                    </div>
                </div>

                <div className="side-content">
                    <h1 className="powered-by">Powered by PAYD TECHNOLOGIES FZ-LLC</h1>
                    <div className="social-media">
                    <a target="_blank" href="https://www.facebook.com/wepayd.ae"> <FontAwesomeIcon icon={faFacebook} size="2x"/></a>
                    <a target="_blank" href="https://twitter.com/PAYD_ae"> <FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                    <a target="_blank" href="https://www.linkedin.com/company/wepayd/"> <FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
                    {/* <a target="_blank" href="https://onnpay.com/contact"> <FontAwesomeIcon icon={faYoutube} size="2x" /></a> */}
                    <a target="_blank" href="https://www.instagram.com/payd.ae/"> <FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>);
}

export default Footer;