import "./About.scss";

function About() {
    return (<div className="About">
        <div className="section__content default-color">
            <h1 className="title-xxl page-title">
            Welcome to OnnPay – Your Trusted Partner in Payment Solutions!
            </h1>
            <p>At OnnPay, we're more than just a payment processing platform; we're your strategic ally on the journey to financial success. Established with a vision to revolutionize payment experiences, we've become a leading force in the world of seamless, secure, and streamlined payments.</p>

            <h2>Our Journey</h2>
            <p>Our story began with a commitment to simplify and enhance the way businesses and individuals handle their financial transactions. With a team of dedicated experts and a deep understanding of the unique needs of the Middle East and North Africa (MENA) region, we set out to create a payment ecosystem that caters to local preferences and global standards.</p>
            <h2>What Sets Us Apart</h2>
            <p>At OnnPay, we take pride in our local expertise, global reach, and unwavering dedication to excellence. We offer a range of cutting-edge solutions designed to make payments simple, secure, and efficient. From customizable payment links and QR codes that facilitate on-demand transactions to automated invoicing and digital receipts that streamline financial management, we're committed to making your life easier.</p>
            <h2>Vision</h2>
            <p>We envision to be the catalyst for a world where payment processes are seamless, secure, and accessible to all. To shape the future of payments, unlocking new possibilities and fostering financial inclusivity across the Middle East and North Africa (MENA) region and beyond.</p>
            <h2>Mission</h2>
            <p>Through cutting-edge technology, local expertise, and unwavering dedication to security, we aim to create an ecosystem where payments are effortless and to be the trusted partner that empowers businesses to grow, individuals to transact with ease, and communities to thrive.</p>
            <h2>Values</h2>
            <p>Innovation, Integrity ,Customer-Centric, Collaboration, Sustainability</p>
            <h2>Why Choose OnnPay</h2>
            <p>1. Local Understanding, Global Presence: With local offices and acquirers across MENA, we bridge the gap between global payment standards and the region's unique requirements, ensuring that your clients experience an instant sense of familiarity with our solutions.</p>
            <p>2. Diverse Payment Options: We empower you to accept payments from virtually anywhere, offering diverse payment methods that cater to your customers' preferences, whether they prefer credit cards, digital wallets, or local alternatives.</p>
            <p>3. Security First: Your peace of mind is paramount. We employ state-of-the-art security measures to safeguard every transaction, ensuring that your financial data is protected at all times.</p>
            <p>4. User-Centric Approach: We've built our services with you in mind. Our interactive dashboard provides insights into your transactions and services, and our developer tools enable seamless integration into your websites and mobile apps.</p>
            <p>5. Dedicated Support: Our commitment to your success extends beyond our technology. Our dedicated support team is always ready to assist you, ensuring that you get the most out of your OnnPay experience.</p>
            <h2>Join the OnnPay Family</h2>
            <p>Whether you're a thriving e-commerce business, a local startup, or an international enterprise looking to expand into the MENA region, OnnPay is here to help you navigate the world of payments with confidence. Join our growing family of satisfied clients and experience the future of payments today.</p>
            <p>Let's embark on this journey together, where simplicity, security, and success converge. 
Welcome to OnnPay – Where Payments Meet Potential.
</p>
        </div>
    </div>);
}

export default About;