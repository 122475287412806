import { Link } from "react-router-dom";
import "./Privacy.scss";

function Privacy() {
    return (<div className="Privacy">
        <div className="section__content default-color">
            <h1 className="title-xxl page-title">
                Privacy policy
            </h1>

            <p>1. Introduction<br></br>
            Welcome to PAYD. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information as a user of our website, www.PAYD.com, and our services. We are committed to safeguarding your privacy and ensuring that your personal data is handled responsibly and in compliance with applicable data protection laws in the United Arab Emirates (UAE).</p>

            <p>2. Information We Collect</p>
            <p>2.1. Information You Provide: We may collect personal information that you voluntarily provide when using our website and services, including but not limited to your name, contact information, payment details, and other relevant data necessary for processing transactions and providing support.</p>
            <p>2.2. Automated Information: When you use our website, we may collect certain information automatically, such as your IP address, browser type, operating system, and device information. We may also use cookies and similar technologies to gather data about your interactions with our website.</p>
            <p>3. How We Use Your Information</p>
            <p>3.1. We use the information we collect for various purposes, including:</p>
            <p>- Facilitating and processing payments.</p>
            <p>- Providing customer support and responding to inquiries.</p>
            <p>- Improving our website's functionality and user experience.</p>
            <p>- Analyzing usage patterns and trends.</p>
            <p>- Complying with legal obligations.</p>
            <p>4. Information Sharing</p>
            <p>4.1. We do not sell, rent, or lease your personal information to third parties.</p>
            <p>4.2. We may share your information with trusted service providers who assist us in delivering our services, such as payment processors, fraud prevention services, and customer support providers. These service providers are contractually obligated to protect your data.</p>
            <p>4.3. We may disclose your information to comply with legal obligations, respond to lawful requests, protect our rights, privacy, safety, or property, or enforce our Terms and Conditions.</p>
            <p>5. Data Security</p>
            <p>5.1. We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please be aware that no data transmission over the internet or method of electronic storage is completely secure.</p>
            <p>6. Your Choices and Rights</p>
            <p>6.1. You have the right to access, correct, or delete your personal information. You can update your account information through your PAYD account settings.</p>
            <p>6.2. You may also opt out of receiving promotional communications from us by following the unsubscribe instructions provided in such communications.</p>
            <p>7. Changes to this Privacy Policy</p>
            <p>7.1. We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting a revised Privacy Policy on our website or through other appropriate channels.</p>
            <p>8. Contact Us</p>
            <p>8.1. If you have questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us at <Link to="mailto:info@PAYD.com">info@PAYD.com</Link> or visit our website at www.PAYD.com for further information.</p>
            
        </div>
    </div>);
}

export default Privacy;