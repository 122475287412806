import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/reset.css';
import './styles/main.scss';

import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Blogs from './pages/Blogs/Blogs';
import Blog from './pages/Blog/Blog';
import About from './pages/About/About';

import { blogsList } from './assets/blogs/blogsList';
import Search from './pages/Search/Search';
import Terms from './pages/Terms/Terms';
import Cookie from './pages/Cookie/Cookie';
import Privacy from './pages/Privacy/Privacy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export function slugify(text: string) {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/search" element={<Search />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/About" element={<About />} />
        <Route path="/Cookie" element={<Cookie />} />
        {blogsList.map((item) => <Route path={"/blog/" + slugify(item.title)} element={<Blog blog={item} />} />)}
        <Route path="*" element={<h1 style={{ textAlign: 'center', margin: 50 }}>404</h1>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);