import "./Feedback.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

function Feedback() {
    return (<div className="Feedback">
        {/* <Button size="m">Feedback</Button> */}
        {/* <a href="/contact"> <Button size="m">Feedback</Button> </a> */}
        <Link to="/contact">
            <Button size="m">Feedback</Button>
        </Link>
    </div>);
}

export default Feedback;