import { Link } from "react-router-dom";
import "./BlogCard.scss";

interface IProps {
    size: "m" | "l",
    image: string,
    category: string, // header
    children: string, // header
    text: string,
    href: string
}

function BlogCard(props: IProps) {
    const { size, image, category, children, text, href } = props;

    return (
        <Link to={href} className={"BlogCard size-" + size}>
            <div className="BlogCard__image" style={{ backgroundImage: "url(" + image + ")" }} />
            <div className="BlogCard__content">

                <div className="BlogCard__category default-color">
                    {category}
                </div>

                <div className="BlogCard__header">
                    {children}
                </div>

                <div className="BlogCard__text">
                    {text}
                </div>

                <div className="BlogCard__button">
                    Read more
                </div>
            </div>
        </Link>
    );
}

export default BlogCard;